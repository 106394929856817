import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Grid from "@mui/material/Unstable_Grid2";
import { Paper, Typography, Backdrop, CircularProgress } from "@mui/material";
import { DataTable } from "../../common/dataTable/DataTable";
import { ProductListingFilters } from "../../common/productListingFilters/ProductListingFilters";
import { AppContext } from "../../../pages/root/Root";
import useProductListing from "../../../hooks/common/useProductListing";
import { initLocalization } from "../../../utils/helpers";
import { productListingStrings } from "./locale";
import {
  DEFAULT_PRODUCT_LISTING_FILTERS,
  MAPPING_KEYS_DATE_FORMAT,
} from "../../../constants";

export const ProductListing = ({
  activationFilterOption,
  mappingKey = {},
  allowSearch,
  onSelectedProductRow,
  onSetValidVisual,
  shouldRefresh,
  isLoading,
}) => {
  initLocalization(productListingStrings);

  const { language } = useContext(AppContext);

  const [filters, setFilters] = useState(DEFAULT_PRODUCT_LISTING_FILTERS);
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    isLoading: productLoading,
    productsData,
    setProductsData,
    getProductsData,
  } = useProductListing(activationFilterOption);

  const columns = useMemo(
    () => [
      {
        field: "productId",
        headerName: productListingStrings.productID,
        minWidth: 65,
      },
      {
        field: "productLabel",
        headerName: productListingStrings.productLabel,
        minWidth: 190,
      },
      {
        field: "advertiserLabel",
        headerName: productListingStrings.advertiserLabel,
        minWidth: 190,
      },
      {
        field: "activationId",
        headerName: productListingStrings.activationID,
        minWidth: 40,
      },
      {
        field: "creationDate",
        headerName: productListingStrings.creationDate,
        minWidth: 82,
        format: (value) => dayjs(value).format(MAPPING_KEYS_DATE_FORMAT),
      },
      {
        field: "groupLabel",
        headerName: productListingStrings.groupLabel,
        minWidth: 190,
      },
      {
        field: "socialAccountName",
        headerName: productListingStrings.socialAccountName,
        minWidth: 180,
        visible: filters.includeSocialAccounts,
      },
    ],
    [filters.includeSocialAccounts, language],
  );

  const keywordsExist = () => {
    /* NOSONAR */ const { includeSocialAccounts: dsa, ...defaultFields } =
      DEFAULT_PRODUCT_LISTING_FILTERS;
    /* NOSONAR */ const { includeSocialAccounts: csa, ...currentFields } = {
      ...filters,
      sourceProductId: mappingKey.productId ?? "",
    };

    return JSON.stringify(defaultFields) !== JSON.stringify(currentFields);
  };

  const resetProductsTable = () => {
    setProductsData([]);
    setSelectedRows([]);
    onSelectedProductRow({});
  };

  const resetProductsFilter = () => {
    setFilters({
      ...DEFAULT_PRODUCT_LISTING_FILTERS,
      includeSocialAccounts: filters.includeSocialAccounts,
    });
  };

  const handleSelectRow = useCallback(
    (e, index) => {
      setSelectedRows([productsData[index].id]);
      onSelectedProductRow(productsData[index]);
    },
    [productsData, onSelectedProductRow],
  );

  const onIncludeSocialAccounts = (disableSearch, includeSocialAccounts) => {
    let payload = {
      ...filters,
      includeSocialAccounts,
      sourceProductId: "",
    };

    if (mappingKey.productId) {
      const sourceProductId = disableSearch ? mappingKey.productId : "";

      payload = {
        ...payload,
        advertiserLabel: disableSearch ? "" : filters.advertiserLabel,
        productLabel: disableSearch ? "" : filters.productLabel,
        sourceProductId: filters.sourceProductId || sourceProductId,
      };
    }

    setProductsData([]);

    if (mappingKey.productId || (!mappingKey.productId && !disableSearch)) {
      getProductsData(payload);
    }
  };

  useEffect(() => {
    if (shouldRefresh && !!productsData.length) {
      setSelectedRows([]);
      onSelectedProductRow({});

      if (!keywordsExist()) {
        return;
      }

      getProductsData({
        ...filters,
        sourceProductId: mappingKey.productId,
      });
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (typeof mappingKey.productId === "number") {
      resetProductsTable();
      resetProductsFilter();
      getProductsData({
        ...DEFAULT_PRODUCT_LISTING_FILTERS,
        includeSocialAccounts: filters.includeSocialAccounts,
        sourceProductId: mappingKey.productId,
      });
    } else {
      resetProductsTable();
      resetProductsFilter();
    }
  }, [mappingKey.productId, mappingKey.id]);

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <Grid container direction="column" height="100%">
        <Grid xs={12}>
          <Typography data-testid="product-listing-title">
            {productListingStrings.title}
          </Typography>
        </Grid>

        <Grid xs={12} marginTop={{ xs: 2, sm: 0 }}>
          <ProductListingFilters
            allowSearch={allowSearch}
            validVisual={mappingKey.isValidVisual}
            filters={filters}
            setFilters={setFilters}
            resetProductsTable={resetProductsTable}
            onSetValidVisual={onSetValidVisual}
            onIncludeSocialAccounts={onIncludeSocialAccounts}
            getProductsData={getProductsData}
            showValidVisualCheckbox={Object.keys(mappingKey).includes(
              "isValidVisual",
            )}
            showSocialAccountsCheckbox={true}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            selectedRows={selectedRows}
            columns={columns}
            rows={productsData}
            showPagination={false}
            allowSelection={true}
            showNoDataMessage={false}
            onSelectRow={handleSelectRow}
            tableMinWidth={762}
          />
        </Grid>
      </Grid>

      <Backdrop
        open={productLoading || isLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

ProductListing.propTypes = {
  activationFilterOption: PropTypes.string.isRequired,
  mappingKey: PropTypes.object,
  allowSearch: PropTypes.bool.isRequired,
  onSelectedProductRow: PropTypes.func.isRequired,
  onSetValidVisual: PropTypes.func,
  shouldRefresh: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};
