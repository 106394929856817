import { useState } from "react";
import Api from "../../utils/Api";
import { API_ROUTE_UPDATE_MAPPING_STATUSES } from "../../routes";
import { MAPPING_KEYS_STATUSES, MAPPING_OPTIONS } from "../../constants";

export default function useMappingKeyStatus(
  mappingOptions = MAPPING_OPTIONS.pathmatics,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(0);

  const updateMappingStatus = (status, payload = {}) => {
    setIsLoading(true);

    return Api.post(API_ROUTE_UPDATE_MAPPING_STATUSES, {
      mappingOptions,
      ...payload,
      status,
    })
      .then(() => {
        setSuccess(new Date().getTime());
        return Promise.resolve();
      })
      .catch((error) => {
        const err =
          error?.response?.data?.errors?.LineIds?.[0] ||
          error?.response?.data?.errors?.productId?.[0];
        setError(err);
        return Promise.reject(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paperClipMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.waitingProductRequested, payload);

  const rejectMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.rejected, payload);

  const rejectBisMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.reject, payload);

  const validateMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.validated, payload);

  const manualDomainMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.manualDomainMapped, payload);

  const mapToCampaignMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.manual, payload);

  const unMapMappingKey = (payload) =>
    updateMappingStatus(MAPPING_KEYS_STATUSES.notMapped, payload);

  return {
    isLoading,
    paperClipMappingKey,
    rejectMappingKey,
    rejectBisMappingKey,
    mapToCampaignMappingKey,
    validateMappingKey,
    unMapMappingKey,
    manualDomainMappingKey,
    error,
    setError,
    success,
  };
}
