import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Link, Button, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { AppAlert } from "../../common/appAlert/AppAlert";
import useMappingKeyStatus from "../../../hooks/common/useMappingKeyStatus";
import useMappingKeys from "../../../hooks/bis/useMappingKeys";
import { MAPPING_KEYS_DATE_FORMAT, MAPPING_OPTIONS } from "../../../constants";

import { initLocalization } from "../../../utils/helpers";
import { poolMetadataStrings } from "./locale";
import "./style.scss";

export const PoolMetadata = ({ creativeDetails, selectedProduct }) => {
  initLocalization(poolMetadataStrings);

  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const { isLoading: actionLoading, mapToCampaignMappingKey } =
    useMappingKeyStatus(MAPPING_OPTIONS.bis);

  const { isLoading: mappingKeysLoading, lockMappingLines } = useMappingKeys();

  const isLoading = actionLoading || mappingKeysLoading;

  const handleUpdateCampaign = async () => {
    try {
      await lockMappingLines(
        {
          shouldLock: true,
          lineIds: [creativeDetails.campaignVm.id],
        },
        true,
      );
      await mapToCampaignMappingKey({
        lineIds: [creativeDetails.campaignVm.id],
        productId: selectedProduct.id,
      });
      await lockMappingLines(
        {
          shouldLock: false,
          lineIds: [creativeDetails.campaignVm.id],
        },
        true,
      );

      setShowSuccess(true);
    } catch (err) {
      setError(err[0]);
    }
  };

  const handleCloseError = () => setError(false);
  const handleCloseMessage = () => setShowSuccess(false);

  return (
    <Grid>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {poolMetadataStrings.errors[error] ||
          poolMetadataStrings.errors.generic}
      </AppAlert>
      <AppAlert
        key="success-message"
        open={showSuccess}
        type="success"
        onClose={handleCloseMessage}
      >
        {poolMetadataStrings.campaignUpdated}
      </AppAlert>
      <Typography paddingLeft="0.5rem" fontWeight="bold">
        {poolMetadataStrings.metadataTitle}:
      </Typography>
      <Grid className="pool-metadata" container flexDirection="column">
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.id}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.creativeId}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.type}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.creativeType}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.date}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {dayjs(creativeDetails.firstOccurrence).format(
              MAPPING_KEYS_DATE_FORMAT,
            )}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.campaign}
          </Typography>
          <Typography className="pool-metadata__table-content--campaign">
            {creativeDetails.campaignVm.campaignTitle}
          </Typography>
          <Button
            className="pool-metadata__update"
            variant="outlined"
            color="primary"
            size="small"
            disabled={isLoading || !selectedProduct.id}
            onClick={handleUpdateCampaign}
          >
            <span style={{ visibility: isLoading ? "hidden" : "visible" }}>
              {poolMetadataStrings.updateL1}
            </span>
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{ position: "absolute", opacity: 0.5 }}
              />
            )}
          </Button>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.product}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.campaignVm.product.name}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.domain}
          </Typography>
          <Typography className="pool-metadata__table-content">
            <Link
              href={creativeDetails.campaignVm.advertiserDomain}
              target="_blank"
            >
              {creativeDetails.campaignVm.advertiserDomain}
            </Link>
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.site}
          </Typography>
          <Typography className="pool-metadata__table-content">
            {creativeDetails.campaignVm.advertiser}
          </Typography>
        </Grid>
        <Grid container width="100%" className="pool-metadata__table-row">
          <Typography className="pool-metadata__table-title">
            {poolMetadataStrings.link}
          </Typography>
          <Typography className="pool-metadata__table-content">
            <Link href={creativeDetails.campaignVm.campaignUrl} target="_blank">
              {poolMetadataStrings.link}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PoolMetadata.propTypes = {
  creativeDetails: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object,
};
