import { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Virtuoso } from "react-virtuoso";
import { Typography, Backdrop, CircularProgress } from "@mui/material";
import { OndemandVideo } from "@mui/icons-material";

import { initLocalization } from "../../../utils/helpers";
import { userPoolThumbnailsStrings } from "./locale";
import { CREATIVES_MEDIA_DOMAIN } from "../../../constants";

import "./style.scss";

export const UserPoolThumbnails = ({
  poolData,
  totalPoolSize,
  isLoading,
  resetSelectedCreatives,
  onCreativesSelection,
  onResetCreativesSelection,
}) => {
  initLocalization(userPoolThumbnailsStrings);

  const ref = useRef(null);
  const lastSelected = useRef(null);
  const [selectedCreatives, setSelectedCreatives] = useState(() => new Set());

  const thumbnailsList = useMemo(() => poolData, [poolData]);

  const getSelected = (currentIndex, allowMultiple, indices) => {
    const prevArray = [...selectedCreatives];
    const current = prevArray.find(
      (id) => id === thumbnailsList[currentIndex]?.id,
    );
    const currentSet =
      current && prevArray.length === 1 ? new Set([current]) : new Set();
    const next = allowMultiple ? new Set(selectedCreatives) : currentSet;

    indices.forEach((idx) => {
      const id = thumbnailsList[idx]?.id;
      next.has(id) && [...next].length > 1 ? next.delete(id) : next.add(id);
    });

    return next;
  };

  const handleSelectCreatives = (event, index) => {
    const indices = new Set();
    let allowMultiple = false;

    indices.add(index);

    if (event.shiftKey) {
      const limits = [lastSelected.current ?? 0, index].sort((a, b) => a - b);
      const [start, end] = limits;

      for (let i = start; i <= end; i++) {
        indices.add(i);
      }

      indices.delete(lastSelected.current);
      allowMultiple = true;
    } else if (event.metaKey || event.ctrlKey) {
      allowMultiple = true;
    }

    const newSelection = getSelected(index, allowMultiple, indices);

    setSelectedCreatives(newSelection);
    onCreativesSelection(
      poolData.filter((creative) => newSelection.has(creative.id)),
    );

    onResetCreativesSelection(false);

    lastSelected.current = index;
  };

  const isSelected = (id) => [...selectedCreatives].includes(id);

  useEffect(() => {
    if (thumbnailsList.length > 0 && resetSelectedCreatives) {
      handleSelectCreatives("click", 0);
      ref.current.scrollTo({ top: 0 });
    }
  }, [thumbnailsList, resetSelectedCreatives]);

  return (
    <Grid className="user-pool-thumbnails">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        paddingY="0.5rem"
      >
        <Typography fontSize={14}>
          ({[...selectedCreatives].length}) {userPoolThumbnailsStrings.selected}
        </Typography>
        <Typography fontSize={14}>
          {thumbnailsList.length} {userPoolThumbnailsStrings.outOf}{" "}
          {totalPoolSize}
        </Typography>
      </Grid>
      <Grid className="user-pool-thumbnails__wrapper">
        <Virtuoso
          ref={ref}
          data={thumbnailsList}
          /* NOSONAR */ itemContent={(index, thumbnail) => {
            const isItemSelected = isSelected(thumbnail.id);

            return (
              <Grid
                key={thumbnail.id}
                className={`user-pool-thumbnails__image-wrapper ${
                  isItemSelected ? "selected" : ""
                } `}
                onClick={(event) => handleSelectCreatives(event, index)}
              >
                {thumbnail.mediaTypeName === "image" && (
                  <img
                    src={thumbnail.location}
                    alt={(
                      thumbnail?.campaignVm?.campaignTitle ||
                      thumbnail?.creativeText
                    )?.slice(0, 100)}
                    className="user-pool-thumbnails__image"
                  />
                )}
                {thumbnail.mediaTypeName === "video" && (
                  <Grid className="user-pool-thumbnails__video-wrapper">
                    <OndemandVideo sx={{ color: "#999", fontSize: 60 }} />
                  </Grid>
                )}
                {thumbnail?.mediaTypeName?.toLowerCase() === "html5" && (
                  <iframe
                    className="user-pool-thumbnails__iframe"
                    title={thumbnail?.id}
                    src={
                      thumbnail?.location?.split(CREATIVES_MEDIA_DOMAIN)?.[1]
                    }
                  />
                )}
              </Grid>
            );
          }}
        />
        <Backdrop
          open={isLoading}
          sx={{
            position: "absolute",
            color: "#fff",
            borderRadius: 1,
            zIndex: (theme) => theme.zIndex.modal + 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
};

UserPoolThumbnails.propTypes = {
  poolData: PropTypes.array.isRequired,
  totalPoolSize: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetSelectedCreatives: PropTypes.bool.isRequired,
  onCreativesSelection: PropTypes.func.isRequired,
  onResetCreativesSelection: PropTypes.func.isRequired,
};
