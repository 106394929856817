import { useContext, useEffect, useState } from "react";
import { useBeforeUnload } from "react-router-dom";
import { MappingKeys } from "../../../components/pathmatics/mappingKeys/MappingKeys";
import { ProductListing } from "../../../components/pathmatics/productListing/ProductListing";
import Grid from "@mui/material/Unstable_Grid2";
import { AppContext } from "../../root/Root";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import {
  AttachFileOutlined,
  BlockOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { AppAlert } from "../../../components/common/appAlert/AppAlert";
import useMappingKeys from "../../../hooks/pathmatics/useMappingKeys";
import useMappingKeyStatus from "../../../hooks/common/useMappingKeyStatus";
import { initLocalization } from "../../../utils/helpers";
import { actionButtonsStrings } from "./locale";
import { productListingStrings } from "../../../components/pathmatics/productListing/locale";
import {
  ACTIVATION_FILTER_OPTIONS,
  MAPPING_KEYS_STATUSES,
} from "../../../constants";
import "../../../style/common.scss";

export const Level1 = () => {
  useContext(AppContext);
  initLocalization(actionButtonsStrings);

  const {
    isLoading: actionLoading,
    paperClipMappingKey,
    rejectMappingKey,
    validateMappingKey,
    success: validateSuccess,
  } = useMappingKeyStatus();
  const { lockMappingLines } = useMappingKeys();

  const [error, setError] = useState(null);
  const [selectedMappingKeys, setSelectedMappingKeys] = useState([]);
  const [selectedLockedMappingKeys, setSelectedLockedMappingKeys] = useState(
    [],
  );
  const [selectedProduct, setSelectedProduct] = useState({});

  let mappingKey = {};

  const allSelectedMappingRows = [
    ...selectedMappingKeys,
    ...selectedLockedMappingKeys,
  ];

  if (allSelectedMappingRows?.length === 1) {
    mappingKey = {
      id: allSelectedMappingRows[0]?.id,
      productId: allSelectedMappingRows[0]?.productDetails?.sourceProductId,
    };
  }

  const checkMappingKeysStatusWaiting = () => {
    const foundItem = selectedMappingKeys?.some((data) => {
      return (
        data.mappingStatus === MAPPING_KEYS_STATUSES.waitingProductRequested
      );
    });

    return foundItem;
  };

  const disablePaperClipButton = () => {
    return !selectedMappingKeys?.length || actionLoading;
  };

  const disableRejectButton = () => {
    return (
      checkMappingKeysStatusWaiting() ||
      !selectedMappingKeys?.length ||
      actionLoading
    );
  };

  const disableValidateButton = () => {
    return (
      checkMappingKeysStatusWaiting() ||
      !selectedProduct.id ||
      !selectedMappingKeys?.length ||
      actionLoading
    );
  };

  const disableSearchActions = () => {
    return Boolean(selectedLockedMappingKeys?.length);
  };

  const buildPayload = (selectedMappingKeys, selectedProductId) => {
    const payload = {
      lineIds: selectedMappingKeys.map((row) => row.id),
    };

    if (selectedProductId) payload.productId = selectedProductId;

    return payload;
  };

  const handleRejectMappingKeys = () => {
    const data = buildPayload(selectedMappingKeys);
    rejectMappingKey(data).catch((err) => setError(err));
  };

  const handlePaperClipMappingKeys = () => {
    const data = buildPayload(selectedMappingKeys);
    paperClipMappingKey(data).catch((err) => setError(err));
  };

  const handleValidateMappingKeys = () => {
    const data = buildPayload(selectedMappingKeys, selectedProduct.id);
    validateMappingKey(data).catch((err) => setError(err));
  };

  const handleCloseError = () => setError(false);

  // Unlock mapping lines on unmount and page unload
  useEffect(() => {
    return () => {
      lockMappingLines({
        shouldLock: false,
      });
    };
  }, []);

  useBeforeUnload(() => {
    lockMappingLines({
      shouldLock: false,
    });
  });

  return (
    <DefaultLayout>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {productListingStrings.errors[error]}
      </AppAlert>

      <Grid
        className="page-content"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        padding={{ xs: 1, md: 2 }}
        disableEqualOverflow
      >
        <Grid md={7}>
          <MappingKeys
            onMappingKeysSelection={setSelectedMappingKeys}
            onLockedMappingKeysSelection={setSelectedLockedMappingKeys}
            shouldRefresh={validateSuccess}
          />
        </Grid>
        <Grid md={5}>
          <Grid container direction="column" columns={12} height="100%">
            <Grid xs={12} flexGrow="1">
              <ProductListing
                activationFilterOption={ACTIVATION_FILTER_OPTIONS.default}
                mappingKey={mappingKey}
                allowSearch={!disableSearchActions()}
                onSelectedProductRow={setSelectedProduct}
                shouldRefresh={validateSuccess}
                isLoading={actionLoading}
              />
            </Grid>
            <Grid
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
              smOffset="auto"
              paddingY={3}
            >
              <Button
                startIcon={<AttachFileOutlined />}
                variant="outlined"
                size="large"
                disabled={disablePaperClipButton()}
                onClick={handlePaperClipMappingKeys}
              >
                {actionButtonsStrings.paperClip}
              </Button>
              <Button
                startIcon={<BlockOutlined />}
                variant="outlined"
                color="error"
                size="large"
                disabled={disableRejectButton()}
                onClick={handleRejectMappingKeys}
              >
                {actionButtonsStrings.reject}
              </Button>
              <Button
                startIcon={<SendOutlined />}
                variant="outlined"
                color="success"
                size="large"
                disabled={disableValidateButton()}
                onClick={handleValidateMappingKeys}
              >
                {actionButtonsStrings.validate}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
