import React, { useState, useContext, memo } from "react";
import { AppContext } from "../../../pages/root/Root";
import PropTypes from "prop-types";

import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@mui/material/";
import { NumericInput } from "../../common/numericInput/NumericInput";

import { Search } from "@mui/icons-material";

import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers/";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { initLocalization } from "../../../utils/helpers";
import { mappingKeysFiltersStrings } from "./locale";

import {
  MAPPING_KEYS_DATE_FORMAT,
  MAPPING_FILTER_DATEPICKER_START_DATE,
  MAPPING_KEYS_API_DATE_FORMAT,
  START_DATE,
  END_DATE,
  DEFAULT_BIS_MAPPING_KEYS_FILTERS,
} from "../../../constants";

import "./style.scss";

const SearchField = ({ searchby, onChange, ...props }) => {
  return searchby === "productId" ? (
    <NumericInput
      {...props}
      setValue={(val) => onChange({ target: { value: val } })}
    />
  ) : (
    <TextField {...props} onChange={onChange} />
  );
};

export const MappingKeysFilters = memo(function MappingKeysFilters({
  filters,
  setFilters,
  setPagination,
  columns,
}) {
  initLocalization(mappingKeysFiltersStrings);
  const { language } = useContext(AppContext);

  const [searchBy, setSearchBy] = useState(
    DEFAULT_BIS_MAPPING_KEYS_FILTERS.keywordSearchField,
  );
  const [searchText, setSearchText] = useState(
    DEFAULT_BIS_MAPPING_KEYS_FILTERS.keyword,
  );

  const DEFAULT_MAPPING_STATUS = "all";

  const handleSetFilters = (event) => {
    setPagination((pagination) => ({
      ...pagination,
      pageNumber: 0,
    }));

    setFilters({
      ...filters,
      keyword: searchText.length > 0 ? searchText : "",
      [event.target.name]: event.target.value,
    });
  };

  const handleSearchBy = (event) => {
    setSearchBy(event.target.value);
    setSearchText("");
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  };

  const disableSearch = () => {
    const insufficientChars = searchText !== "" && searchText.trim().length < 1;
    const emptyField = searchText === "";

    return insufficientChars || emptyField;
  };

  const handleSearchByKeyword = () => {
    if (disableSearch()) {
      return;
    }

    setPagination((pagination) => ({
      ...pagination,
      pageNumber: 0,
    }));

    setFilters({
      ...filters,
      keywordSearchField: searchBy,
      keyword: searchText,
      status: filters.status || DEFAULT_MAPPING_STATUS,
    });
  };

  const handleFieldKeydown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      handleSearchByKeyword();
    }
  };

  const handleDateChange = (value, name) => {
    setPagination((pagination) => ({
      ...pagination,
      pageNumber: 0,
    }));

    setFilters({
      ...filters,
      [name]: dayjs(value).format(MAPPING_KEYS_API_DATE_FORMAT),
      status: filters.status || DEFAULT_MAPPING_STATUS,
    });
  };

  return (
    <Grid container className="mapping-filters">
      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
      >
        <Box
          sx={{
            "&": {
              flex: "1",
            },
            "& .MuiTextField-root": {
              width: "100%",
              background: "#fff",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="status-label">
              {mappingKeysFiltersStrings.mappingStatus}
            </InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={filters.status}
              label="Status"
              onChange={handleSetFilters}
              name="status"
            >
              <MenuItem value="auto">{mappingKeysFiltersStrings.auto}</MenuItem>
              <MenuItem value="manual">
                {mappingKeysFiltersStrings.manual}
              </MenuItem>
              <MenuItem value="reject">
                {mappingKeysFiltersStrings.reject}
              </MenuItem>
              <MenuItem value="notMapped">
                {mappingKeysFiltersStrings.notMapped}
              </MenuItem>
              <MenuItem value="manualDomainMapped">
                {mappingKeysFiltersStrings.manualDomainMapped}
              </MenuItem>
              <MenuItem value="autoDomainMapped">
                {mappingKeysFiltersStrings.autoDomainMapped}
              </MenuItem>
              <MenuItem value="productCreated">
                {mappingKeysFiltersStrings.productCreated}
              </MenuItem>
              <MenuItem value="productRequested">
                {mappingKeysFiltersStrings.productRequested}
              </MenuItem>
              <MenuItem value="all">{mappingKeysFiltersStrings.all}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className="mapping-filter__search-container">
          <FormControl className="mapping-filter__search-select" size="small">
            <InputLabel id="status-label">
              {mappingKeysFiltersStrings.searchBy}
            </InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={searchBy}
              label={mappingKeysFiltersStrings.searchBy}
              onChange={handleSearchBy}
            >
              <MenuItem value="all">{mappingKeysFiltersStrings.all}</MenuItem>
              {[
                ...columns,
                {
                  field: "productId",
                  headerName: mappingKeysFiltersStrings.productId,
                },
              ]
                .filter(
                  (column) =>
                    column.field !== "status" &&
                    column.field !== "firstOccurrence",
                )
                .map((column) => (
                  <MenuItem key={column.field} value={column.field}>
                    {column.headerName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <SearchField
            id="outlined-disabled"
            label={
              searchBy === "productId"
                ? mappingKeysFiltersStrings.searchNumber
                : mappingKeysFiltersStrings.searchText
            }
            size="small"
            className="mapping-filter__search-text"
            value={searchText}
            onChange={handleSearchText}
            onKeyDown={handleFieldKeydown}
            searchby={searchBy}
          />
          <div className="mapping-filter__search-button-wrapper">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                padding: "2px",
                minWidth: "32px",
              }}
              onClick={handleSearchByKeyword}
              disabled={disableSearch()}
            >
              <Search />
            </Button>
          </div>
        </Box>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          <DatePicker
            label={mappingKeysFiltersStrings.startDate}
            slotProps={{ textField: { size: "small", readOnly: true } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
            format={MAPPING_KEYS_DATE_FORMAT}
            value={filters.startDate}
            minDate={dayjs(MAPPING_FILTER_DATEPICKER_START_DATE.default)}
            maxDate={dayjs(new Date())}
            onChange={(value) => handleDateChange(value, START_DATE)}
          />

          <DatePicker
            label={mappingKeysFiltersStrings.endDate}
            slotProps={{ textField: { size: "small", readOnly: true } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
            format={MAPPING_KEYS_DATE_FORMAT}
            value={filters.endDate}
            minDate={dayjs(MAPPING_FILTER_DATEPICKER_START_DATE.default)}
            maxDate={dayjs(new Date())}
            onChange={(value) => handleDateChange(value, END_DATE)}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
});

SearchField.propTypes = {
  searchby: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

MappingKeysFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};
